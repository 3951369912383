<template>
    <div>
        <div v-if="loader">
            <div v-for="(item, i) in 4" :key="i">
                <categoriesPlaceholder></categoriesPlaceholder>
            </div>
        </div>
    <div class="grid-view" v-else>
        <noorPlayGrid
            :contents="screenContent"
            :section="screen"
            :message="''"
            :isScrollable="true"
            :playerInstance=playerInstance
            :isMetaRedirection = true
            @loadMore="loadMoreContents()"
        ></noorPlayGrid>
    </div>
    </div>
</template>

<script>
    import { eventBus } from "@/eventBus";
    import { mapGetters } from "vuex";
    import { store } from "@/store/store";
    export default {
        data () {
            return  {
                loader: true,
                screenContent: [],
                screen: {}
            }
        },
        props: {
            playerInstance: {
                type: Object,
            }
        },
        methods: {
            loadMoreContents () {

            }
        },
        components: {
            categoriesPlaceholder: () =>
      import(/* webpackChunkName: "categoriesPlaceholder" */ "@/components/placeholders/categoriesPlaceholder.vue"),
    noorPlayGrid: () =>
      import(/* webpackChunkName: "categoriesPlaceholder" */ "@/components/Templates/noorPlayGrid.vue"),
        },
        computed: {
            ...mapGetters(["appConfig"]),
            },
        mounted () {
            this.screen = {
                displayType: "PORTRAIT"
            }
      
            console.log("this.$router.currentRoute.params", this.$router.currentRoute.params)

            let customParams = {
                objecttype: this.$router.currentRoute.params.objecttype
            }
            customParams[this.$router.currentRoute.params.type] = this.$router.currentRoute.params.value;

           let  payload =  {
                endpoint: 'subscriber/v1/content',
                params: customParams,
            }
            payload.params.displaylanguage = localStorage.getItem("setDisplayLanguageCode");
             payload.params.page = 1;

  
                store
                .dispatch("contentListMetaCall", payload)
                .then((response) => {
                    if (response.data) {
                        this.loader = false;
                        this.screenContent = response.data.data
                    }
                })
                .catch((err) => console.log(err));
            }
        }

</script>

<style lang="scss" scoped>
    .grid-view {
        margin: 0 auto;
        width: 100vw;
        height: 100%;
    }
</style>